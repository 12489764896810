import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Multiselect,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getGroupInfo, getTaggedResources, listDataPermissions } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isValidPrincipalNotRoot, isValidWorkspace } from 'src/commons/validationUtils';
import { getConsumerDataLakePrincipal, getFoundryRoleARN } from 'src/commons/common';
import {
  addToCartAccessTypeSelection,
  addToCartAccessTypeSelectionWithGalaxi,
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  DATABASE_RESOURCE_TYPE,
  DATASET_RESOURCE_TYPE,
  FGA_POLICY_TYPE_PREFIX,
  GALAXI_DATASOURCE_ID,
  GALAXI_NEW_DATA_ACCESS_CATEGORY,
  GALAXI_NEW_DATA_ACCESS_ITEM,
  GALAXI_NEW_DATA_ACCESS_TYPE,
  GALAXI_TYPE,
  LAKE_FORMATION_TAG_ITEM,
  LAKEFORMATION_FGA_POLICY_TYPE,
  LAKEFORMATION_RESOURCE,
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_RESOURCE,
  REDSHIFT_TAG_ITEM,
  REDSHIFT_TAG_TYPE,
  RESOURCE_ITEM,
  RESOURCE_LINK_PREFIX,
  SCHEMA_RESOURCE_TYPE,
  TABLE_RESOURCE_TYPE,
  TAG_ITEM,
  WEBSITE_NAME,
} from 'src/commons/constants';
import {
  createFgaPolicyViewLink,
  createDatasetDetailLink,
  createGlueDatabaseDetailLink,
  createSchemaDetailLink,
} from 'src/routes';
import {
  constructTicketUrlWithParams,
  getGalaxiSubscriptionURL,
  isMPData,
} from 'src/components/workspaces/common/common';
import { GetGroupResult } from 'aws-sdk/clients/awsdatalakegladstonelambda';

export interface AccessSelectModalProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace?: any;
  visible: boolean;
  dismiss: any;
  dataset: any;
  addToCart: any;
  catalogName: any;
  tags: any[];
  fgaPolicies: any[];
  cartItemIds: any;
  cartItems?: any;
  externalGroups?: any;
  allowListed?: boolean;
  preSelectedPermissionType?: any;
}

export const AccessSelectModal = (props: AccessSelectModalProps) => {
  const [selectedPermissionType, setSelectedPermissionType] = useState(props.preSelectedPermissionType);
  const [selectedPermission, setSelectedPermission] = useState(undefined);
  const [selectedPermissions, setSelectedPermissions] = useState(undefined);
  const [allItems, setItems] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedPrincipalType, setSelectedPrincipalType] = useState(undefined);
  const [principal, setPrincipal] = useState(undefined);
  const [principalErrorText, setPrincipalErrorText] = useState(undefined);
  const [dropdownPrincipalOptions, setDropDownPrincipalOptions] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const [buttonText, setButtonText] = useState('Add to cart');

  const isOnboardedToGalaxi = props.externalGroups?.length > 0;
  const isGalaxiPermission = selectedPermissionType?.value == GALAXI_TYPE;
  const isGroupServiceEnabled = localStorage.getItem('groupServiceSupported')?.toLowerCase() == 'true';
  const flattenDatasetAccess = async (selectedPermission) => {
    return {
      datasetName: selectedPermission.datasetName,
      databaseName: selectedPermission.databaseName,
      tableName: selectedPermission.datasetName,
      catalogId: selectedPermission.catalogId,
      region: props.dataset?.IdInfo?.Region,
      catalogDisplayName: selectedPermission.catalogName,
      dataOwnerRole: isValidWorkspace(selectedPermission.owner)
        ? getFoundryRoleARN(props.dataset?.IdInfo?.Region, selectedPermission.catalogId)
        : selectedPermission.publisherRole,
      owner: selectedPermission.owner,
      itemType: RESOURCE_ITEM,
      permissionId: selectedPermission.permissionId,
      catalogName: selectedPermission.catalogName,
      id: selectedPermission.id,
      dataLakePrincipal: principal === undefined ? props.activeWorkspace.accountId : principal,
    };
  };

  let flattenTagAccess = async (selectedPermission) => {
    let catalogId = selectedPermission.catalogId;
    let catalogName = selectedPermission.catalogName;
    let permissionId = selectedPermission.permissionId;
    let tagKey = selectedPermission.tagKey;
    let tagValue = selectedPermission.tagValue;
    let owner = selectedPermission.owner;
    let id = selectedPermission.id;
    let resources = [];
    let getTaggedResourcesResponse = await getTaggedResources({
      tagPair: tagKey + ':' + tagValue,
      catalogId: selectedPermission.catalogId,
      region: props.dataset?.IdInfo?.Region,
      type: props.dataset?.ClusterIdentifier != null ? REDSHIFT_TAG_TYPE : LAKEFORMATION_TAG_TYPE,
    });

    for (let resource of getTaggedResourcesResponse.resources) {
      if (resource['resourceType'] == DATASET_RESOURCE_TYPE) {
        if (resource['schemaName'] != null) {
          resources.push(
            `${catalogName}.${resource['databaseName']}.${resource['schemaName']}.${resource['resourceName']}`,
          );
        } else {
          resources.push(`${catalogName}/${resource['databaseName']}/${resource['resourceName']}`);
        }
      } else if (resource['resourceType'] == DATABASE_RESOURCE_TYPE) {
        resources.push(`${catalogName}/${resource['databaseName']}`);
      } else if (resource['resourceType'] == SCHEMA_RESOURCE_TYPE) {
        resources.push(`${catalogName}.${resource['databaseName']}.${resource['databaseName']}`);
      }
    }

    let item = {
      tagKey: tagKey,
      tagValue: tagValue,
      tag: tagKey + '.' + tagValue,
      owner: owner,
      permissionId: permissionId,
      itemType: props.dataset?.ClusterIdentifier != null ? REDSHIFT_TAG_ITEM : LAKE_FORMATION_TAG_ITEM,
      resources: resources,
      catalogDisplayName: catalogName,
      dataOwnerRole: getFoundryRoleARN(props.dataset?.IdInfo?.Region, catalogId),
      catalogId: catalogId,
      catalogName: catalogName,
      id: id,
      region: props.dataset?.IdInfo?.Region,
      dataLakePrincipal:
        props.dataset?.ClusterIdentifier != null
          ? getConsumerDataLakePrincipal(REDSHIFT_RESOURCE, undefined, props.activeWorkspace.accountId)
          : getConsumerDataLakePrincipal(LAKEFORMATION_RESOURCE, principal, props.activeWorkspace.accountId),
    };
    return item;
  };

  let flattenFineGrainAccess = async (selectedPermission) => {
    return {
      catalogDisplayName: selectedPermission.catalogName,
      catalogId: selectedPermission.catalogId,
      clusterId: selectedPermission.clusterId,
      databaseName: selectedPermission.databaseName,
      schemaName: selectedPermission.schemaName,
      tableName: selectedPermission.tableName,
      region: props.dataset?.IdInfo?.Region,
      dataOwnerRole: getFoundryRoleARN(props.dataset?.IdInfo?.Region, selectedPermission.catalogId),
      owner: selectedPermission.ownerId,
      itemType: FGA_POLICY_TYPE_PREFIX,
      permissionId: selectedPermission.permissionId,
      fgapName: selectedPermission.fgapName,
      id: selectedPermission.id,
      dataLakePrincipal:
        props.dataset?.ClusterIdentifier != null
          ? getConsumerDataLakePrincipal(REDSHIFT_RESOURCE, undefined, props.activeWorkspace.accountId)
          : getConsumerDataLakePrincipal(LAKEFORMATION_RESOURCE, principal, props.activeWorkspace.accountId),
    };
  };

  const checkIfTagIsAlreadyConsumedAndGetPublisherTag = async (tag, tagWorkspaceId) => {
    let tagKey = tag.tagKey;
    let tagValue = tag.tagValue;
    let tagAccountId = tag.catalogId;
    let tagId = `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`;

    let listTagPermissionPublisherRequest;
    let listTagPermissionConsumerRequest;

    if (props.dataset?.ClusterIdentifier != null) {
      listTagPermissionPublisherRequest = {
        ownerId: tagWorkspaceId,
        region: props.dataset?.IdInfo?.Region,
        resource: {
          redshiftTagPolicy: {
            tagKey: tagKey,
            tagValue: tagValue,
            catalogId: tagAccountId,
          },
        },
        option: DATA_PERMISSION_PUBLISHER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: REDSHIFT_TAG_TYPE,
        nextToken: null,
      };
      listTagPermissionConsumerRequest = {
        ownerId: props.activeWorkspace.workspaceId,
        region: props.dataset?.IdInfo?.Region,
        resource: {
          redshiftTagPolicy: {
            tagKey: tagKey,
            tagValue: tagValue,
            catalogId: tagAccountId,
          },
        },
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: REDSHIFT_TAG_TYPE,
        nextToken: null,
      };
    } else {
      listTagPermissionPublisherRequest = {
        ownerId: tagWorkspaceId,
        region: props.dataset?.IdInfo?.Region,
        resource: {
          lFTagPolicy: {
            resourceType: TABLE_RESOURCE_TYPE,
            expression: [
              {
                tagKey: tagKey,
                tagValues: [tagValue],
              },
            ],
            catalogId: tagAccountId,
          },
        },
        option: DATA_PERMISSION_PUBLISHER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: LAKEFORMATION_TAG_TYPE,
        nextToken: null,
      };
      listTagPermissionConsumerRequest = {
        ownerId: props.activeWorkspace.workspaceId,
        region: props.dataset?.IdInfo?.Region,
        resource: {
          lFTagPolicy: {
            resourceType: TABLE_RESOURCE_TYPE,
            expression: [
              {
                tagKey: tagKey,
                tagValues: [tagValue],
              },
            ],
            catalogId: tagAccountId,
          },
        },
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        type: LAKEFORMATION_TAG_TYPE,
        nextToken: null,
      };
    }

    let accountConsumed = undefined;
    let permissionId = undefined;
    let dataLakePrincipals = [];
    let dataLakeIAMPrincipals = [];
    try {
      let listTagPermissionPublisherResponse = await listDataPermissions(listTagPermissionPublisherRequest);
      let listTagPermissionConsumersResponse = await listDataPermissions(listTagPermissionConsumerRequest);
      // get publisher tag data permission id
      if (
        listTagPermissionPublisherResponse?.dataPermissionList !== undefined &&
        listTagPermissionPublisherResponse?.dataPermissionList.length != 0
      ) {
        permissionId = listTagPermissionPublisherResponse.dataPermissionList[0].dataPermissionId;
      }
      // check if the tag is already consumed
      dataLakePrincipals = [
        ...listTagPermissionConsumersResponse.dataPermissionList.map(
          (dataPermission) => dataPermission.dataLakePrincipal,
        ),
        ...props.cartItems
          .filter((dataPermission) => dataPermission.id.equals(tagId))
          .map((dataPermission) => dataPermission.dataLakePrincipal),
      ];
      dataLakeIAMPrincipals = dataLakePrincipals.filter((dataLakePrincipal) =>
        isValidPrincipalNotRoot(dataLakePrincipal),
      );
      //Current workspace already consuming the all tables in database
      accountConsumed =
        dataLakePrincipals.filter((dataLakePrincipal) => props.activeWorkspace.workspaceId.includes(dataLakePrincipal))
          .length !== 0;
    } catch (err) {
      console.log('Exception list tag permission', err);
      return permissionId;
    }
    return {
      permissionId: permissionId,
      consumed: accountConsumed,
      iamPrincipals: dataLakeIAMPrincipals,
    };
  };

  const getConsumedPermissionsOfFineGrainPolicy = async (policy) => {
    let consumedPermissions = [];
    let listConsumerDataPermissionsForFgaPolicyResult = await listDataPermissions({
      ownerId: props.activeWorkspace.workspaceId,
      region: props.dataset?.IdInfo?.Region,
      resource: {
        dataCellsFilter: {
          tableCatalogId: policy.tableData.tableCatalogId,
          clusterId: policy.tableData.clusterId,
          databaseName: policy.tableData.databaseName,
          schemaName: policy.tableData.schemaName,
          tableName: policy.tableData.tableName,
          name: policy.tableData.physicalName,
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type:
        policy.type === LAKEFORMATION_FGA_POLICY_TYPE
          ? DATA_PERMISSION_LAKE_FORMATION_TYPE
          : DATA_PERMISSION_REDSHIFT_TYPE,
      nextToken: null,
    });
    consumedPermissions.push(...listConsumerDataPermissionsForFgaPolicyResult.dataPermissionList);
    while (listConsumerDataPermissionsForFgaPolicyResult.nextToken) {
      listConsumerDataPermissionsForFgaPolicyResult = await listDataPermissions({
        ownerId: props.activeWorkspace.workspaceId,
        option: DATA_PERMISSION_CONSUMER_OPTION,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        resource: {
          dataCellsFilter: {
            tableCatalogId: policy.tableData.tableCatalogId,
            databaseName: policy.tableData.databaseName,
            tableName: policy.tableData.tableName,
            name: policy.tableData.name,
          },
        },
        type:
          policy.type === LAKEFORMATION_FGA_POLICY_TYPE
            ? DATA_PERMISSION_LAKE_FORMATION_TYPE
            : DATA_PERMISSION_REDSHIFT_TYPE,
        nextToken: listConsumerDataPermissionsForFgaPolicyResult.nextToken,
      });
      consumedPermissions.push(...listConsumerDataPermissionsForFgaPolicyResult.dataPermissionList);
    }
    return consumedPermissions.filter((permission) => {
      return policy.id === permission.fgapId;
    });
  };

  const verifyAllTableAccess = async (dataset, id) => {
    let databaseName = dataset?.IdInfo?.DatabaseName;
    let catalogId = dataset?.IdInfo?.CatalogId;
    // get all tables publisher
    let listDatasetPermissionRequestAllTablesPublisher = {
      region: props.dataset?.IdInfo?.Region,
      resource: {
        table: {
          databaseName: databaseName,
          name: null,
          catalogId: catalogId,
          tableWildcard: {},
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    // check if current workspace is a all tables consumer
    let listDatasetPermissionRequestAllTablesConsumer = {
      ownerId: props.activeWorkspace?.workspaceId,
      region: props.dataset?.IdInfo?.Region,
      resource: {
        table: {
          databaseName: databaseName,
          name: null,
          catalogId: catalogId,
          tableWildcard: {},
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };

    let accountConsumed = undefined;
    let dataLakePrincipals = [];
    let dataLakeIAMPrincipals = [];
    let permissionId = undefined;
    let publisherRole = undefined;
    try {
      let listDatasetPermissionResponsePublisherAllTables = await listDataPermissions(
        listDatasetPermissionRequestAllTablesPublisher,
      );
      let listDatasetPermissionResponseConsumerAllTables = await listDataPermissions(
        listDatasetPermissionRequestAllTablesConsumer,
      );
      if (
        listDatasetPermissionResponsePublisherAllTables?.dataPermissionList &&
        listDatasetPermissionResponsePublisherAllTables.dataPermissionList.length != 0
      ) {
        permissionId = listDatasetPermissionResponsePublisherAllTables.dataPermissionList[0].dataPermissionId;
        publisherRole = listDatasetPermissionResponsePublisherAllTables.dataPermissionList[0].audit.PublisherRole;
      }
      if (
        listDatasetPermissionResponseConsumerAllTables?.dataPermissionList &&
        listDatasetPermissionResponseConsumerAllTables.dataPermissionList.length != 0
      ) {
        //Get list of datalake Principals
        dataLakePrincipals = [
          ...listDatasetPermissionResponseConsumerAllTables.dataPermissionList.map(
            (dataPermission) => dataPermission.dataLakePrincipal,
          ),
          ...props.cartItems
            .filter((dataPermission) => dataPermission.id.equals(id))
            .map((dataPermission) => dataPermission.dataLakePrincipal),
        ];
        dataLakeIAMPrincipals = dataLakePrincipals.filter((dataLakePrincipal) =>
          isValidPrincipalNotRoot(dataLakePrincipal),
        );
        //Current workspace already consuming the all tables in database
        accountConsumed =
          dataLakePrincipals.filter((dataLakePrincipal) =>
            props.activeWorkspace.workspaceId.includes(dataLakePrincipal),
          ).length !== 0;
      }
    } catch (err) {
      console.log('Exception list tag permission', err);
      return undefined;
    }
    return {
      permissionId: permissionId,
      accountConsumed: accountConsumed,
      publisherRole: publisherRole,
      iamPrincipals: dataLakeIAMPrincipals,
    };
  };

  const getAllTableAccessItem = async (dataset, singleDatasetConsumed, iamPrincipals) => {
    let allTablesResourceId = `All Tables.${dataset?.IdInfo?.DatabaseName}.${dataset?.IdInfo?.CatalogId}`;
    let singleTablesResourceId = `${dataset?.IdInfo?.TableName}.${dataset?.IdInfo?.DatabaseName}.${dataset?.IdInfo?.CatalogId}`;
    let permissionData = await verifyAllTableAccess(dataset, allTablesResourceId);
    if (permissionData == undefined) {
      return undefined;
    }
    let databasePermissionId = permissionData.permissionId;
    let allTablesConsumed = permissionData.accountConsumed;
    let publisherRole = permissionData.publisherRole;
    let itemsToReturn = [];

    if (databasePermissionId !== undefined) {
      //block mp data table access
      if (isMPData(dataset?.IdInfo?.CatalogId, dataset?.IdInfo?.DatabaseName, dataset?.IdInfo?.TableName)) {
        return [];
      }

      let allTablesPermission = {
        label: `Request "All Tables" access from ` + `"${dataset?.IdInfo?.DatabaseName}"`,
        id: allTablesResourceId,
        value: 'workspaceAccess' + allTablesResourceId,
        type: RESOURCE_ITEM,
        datasetName: 'All Tables',
        databaseName: dataset?.IdInfo?.DatabaseName,
        owner: dataset?.PrimaryOwner,
        catalogId: dataset?.IdInfo?.CatalogId,
        catalogName: props.catalogName,
        permissionId: databasePermissionId,
        workspaceAccess: allTablesConsumed,
        publisherRole: publisherRole,
        principals: permissionData.iamPrincipals,
      };
      let singleTablePermission = {
        label: `Request access to ` + `"${dataset?.IdInfo?.TableName}"`,
        id: singleTablesResourceId,
        value: singleTablesResourceId,
        type: RESOURCE_ITEM,
        datasetName: dataset?.IdInfo?.TableName,
        databaseName: dataset?.IdInfo?.DatabaseName,
        owner: dataset?.PrimaryOwner,
        catalogId: dataset?.IdInfo?.CatalogId,
        catalogName: props.catalogName,
        permissionId: databasePermissionId,
        workspaceAccess: singleDatasetConsumed,
        publisherRole: publisherRole,
        principals: iamPrincipals,
      };
      itemsToReturn.push(singleTablePermission);
      itemsToReturn.push(allTablesPermission);
      return itemsToReturn;
    }
    return undefined;
  };

  const getSingleTableAccessDataPermission = async (dataset, resourceId) => {
    let tableName = dataset?.IdInfo?.TableName;
    let databaseName = dataset?.IdInfo?.DatabaseName;
    let datasetOwnerId = dataset?.PrimaryOwner;
    let catalogId = dataset?.IdInfo?.CatalogId;
    // get single table publisher permission if present
    let listDatasetPermissionRequestPublisher = {
      ownerId: datasetOwnerId,
      region: props.dataset?.IdInfo?.Region,
      resource: {
        table: {
          databaseName: databaseName,
          name: tableName,
          catalogId: catalogId,
          tableWildcard: null,
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    // get single table consumer permission of current workspace if present
    let listDatasetPermissionRequestConsumer = {
      ownerId: props.activeWorkspace?.workspaceId,
      region: props.dataset?.IdInfo?.Region,
      resource: {
        table: {
          databaseName: databaseName,
          name: tableName,
          catalogId: catalogId,
          tableWildcard: null,
        },
      },
      option: DATA_PERMISSION_CONSUMER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };

    let accountConsumed = undefined;
    let dataLakePrincipals = [];
    let dataLakeIAMPrincipals = [];
    let permissionId = undefined;
    let publisherRole = undefined;

    try {
      let listDatasetPermissionResponsePublisher = await listDataPermissions(listDatasetPermissionRequestPublisher);
      let listDatasetPermissionResponseConsumer = await listDataPermissions(listDatasetPermissionRequestConsumer);
      let publisherPermissionAsList = listDatasetPermissionResponsePublisher.dataPermissionList;
      let consumerPermissionAsList = listDatasetPermissionResponseConsumer.dataPermissionList;
      //filter out fga permissions and resource links
      consumerPermissionAsList = consumerPermissionAsList.filter((permission) => {
        return permission.fgapId === null && !permission.metadata.includes(RESOURCE_LINK_PREFIX);
      });
      for (let permission of publisherPermissionAsList) {
        if (
          permission.option == DATA_PERMISSION_PUBLISHER_OPTION &&
          (permission?.resource?.table !== undefined ||
            (permission?.resource.tableWithColumns !== undefined &&
              Object.keys(permission?.resource.tableWithColumns).length))
        ) {
          permissionId = permission.dataPermissionId;
          publisherRole = permission.audit.PublisherRole;
        }
      }

      if (consumerPermissionAsList && consumerPermissionAsList.length !== 0) {
        //Get list of datalake Principals
        dataLakePrincipals = [
          ...consumerPermissionAsList.map((dataPermission) => dataPermission.dataLakePrincipal),
          ...props.cartItems
            .filter((dataPermission) => dataPermission.id.equals(resourceId))
            .map((dataPermission) => dataPermission.dataLakePrincipal),
        ];
        dataLakeIAMPrincipals = dataLakePrincipals.filter((dataLakePrincipal) =>
          isValidPrincipalNotRoot(dataLakePrincipal),
        );
        //Current workspace already consuming the all tables in database
        accountConsumed =
          dataLakePrincipals.filter((dataLakePrincipal) =>
            props.activeWorkspace.workspaceId.includes(dataLakePrincipal),
          ).length !== 0;
      }
    } catch (err) {
      console.log('Exception list tag permission', err);
      return permissionId;
    }
    return {
      permissionId: permissionId,
      accountConsumed: accountConsumed,
      publisherRole: publisherRole,
      iamPrincipals: dataLakeIAMPrincipals,
    };
  };

  const setupDropdownItems = async (tags, fgaPolicies, dataset, externalGroups) => {
    let dropdownItems = [];

    if (externalGroups != null) {
      for (let externalGroup of externalGroups) {
        dropdownItems.push({
          label: externalGroup?.groupName,
          value: externalGroup?.groupName,
          type: GALAXI_TYPE,
          teamName: externalGroup?.teamName,
        });
      }
    }

    // set up tag drop down items
    for (let tag of tags) {
      let tagId = `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`;
      let permissionData = await checkIfTagIsAlreadyConsumedAndGetPublisherTag(tag, dataset.Owners[0]);
      let tagPermissionId = permissionData?.permissionId;
      let consumed = permissionData?.consumed;
      if (tagPermissionId !== undefined) {
        dropdownItems.push({
          label: `Request ${tag.tagKey}.${tag.tagValue} access`,
          id: tagId,
          type: TAG_ITEM,
          value: tagId,
          tagKey: tag.tagKey,
          tagValue: tag.tagValue,
          catalogId: tag.catalogId,
          owner: dataset.Owners[0],
          catalogName: props.catalogName,
          permissionId: tagPermissionId,
          workspaceAccess: consumed,
          principals: permissionData.iamPrincipals,
        });
      }
    }
    // set up resource drop down items
    let resourceId = `${dataset?.IdInfo?.TableName}.${dataset?.IdInfo?.DatabaseName}.${dataset?.IdInfo?.CatalogId}`;
    let permissionData = await getSingleTableAccessDataPermission(dataset, resourceId);
    let datasetPermissionId = permissionData?.permissionId;
    let isSingleDatasetConsumed = permissionData?.consumed;
    let publisherRole = permissionData?.publisherRole;
    let singleTableAccessDropDown = undefined;
    if (datasetPermissionId) {
      //block mp data table level access
      if (!isMPData(dataset?.IdInfo?.CatalogId, dataset?.IdInfo?.DatabaseName, dataset?.IdInfo?.TableName)) {
        singleTableAccessDropDown = {
          label: `Request access to ` + `"${dataset?.IdInfo?.TableName}"`,
          id: resourceId,
          value: resourceId,
          type: RESOURCE_ITEM,
          datasetName: dataset?.IdInfo?.TableName,
          databaseName: dataset?.IdInfo?.DatabaseName,
          owner: dataset?.PrimaryOwner,
          catalogId: dataset?.IdInfo?.CatalogId,
          catalogName: props.catalogName,
          permissionId: datasetPermissionId,
          workspaceAccess: isSingleDatasetConsumed,
          publisherRole: publisherRole,
          principals: permissionData?.iamPrincipals,
        };
        dropdownItems.push(singleTableAccessDropDown);
      }
    }
    let allTableDropDownItems = await getAllTableAccessItem(
      dataset,
      isSingleDatasetConsumed,
      permissionData?.iamPrincipals,
    );
    if (allTableDropDownItems && allTableDropDownItems.length !== 0) {
      // remove duplicate single table request field
      for (let allTableItem of allTableDropDownItems) {
        //block mp data db level access
        if (isMPData(allTableItem['catalogId'], allTableItem['databaseName'], allTableItem['datasetName'])) {
          continue;
        }

        if (singleTableAccessDropDown !== undefined) {
          if (allTableItem['id'] !== singleTableAccessDropDown['id']) {
            dropdownItems.push(allTableItem);
          }
        } else {
          dropdownItems.push(allTableItem);
        }
      }
    }
    // set up fga drop down items
    for (let fgaPolicy of fgaPolicies) {
      let consumedPermission = await getConsumedPermissionsOfFineGrainPolicy(fgaPolicy);
      dropdownItems.push({
        label: `Request access to ` + `"${fgaPolicy.tableData.physicalName}"`,
        id: fgaPolicy.id,
        fgapName: fgaPolicy.tableData.physicalName,
        type: FGA_POLICY_TYPE_PREFIX,
        value: fgaPolicy.id,
        catalogId: fgaPolicy.tableData.tableCatalogId,
        clusterId: fgaPolicy.tableData.clusterId,
        databaseName: fgaPolicy.tableData.databaseName,
        schemaName: fgaPolicy.tableData.schemaName,
        tableName: fgaPolicy.tableData.tableName,
        ownerId: fgaPolicy.ownerId,
        catalogName: props.catalogName,
        permissionId: fgaPolicy.dataPermissionId,
        workspaceAccess:
          consumedPermission.filter((consumedPermission) =>
            props.activeWorkspace.workspaceId.includes(consumedPermission.dataLakePrincipal),
          ).length !== 0,
        principals: consumedPermission
          .map((consumedPermission) => consumedPermission.dataLakePrincipal)
          .filter((dataLakePrincipal) => isValidPrincipalNotRoot(dataLakePrincipal)),
      });
    }
    setDropdownOptions(dropdownItems);
  };

  const handleRefresh = async () => {
    setLoadingResources(true);
    if (props.dataset?.IdInfo.DataSourceId == GALAXI_DATASOURCE_ID) {
      setButtonText('Request Access');
    } else if (isGalaxiPermission) {
      setButtonText('Cut access ticket to Galaxi support team');
    }
    await setupDropdownItems(props.tags, props.fgaPolicies, props.dataset, props.externalGroups);
    setLoadingResources(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleConfirm = async () => {
    if (isGalaxiPermission && props.dataset?.IdInfo.DataSourceId == GALAXI_DATASOURCE_ID) {
      if (selectedPermission) {
        //selected group is selectedPermission?.value
        //need to append it to url
        window.open(
          getGalaxiSubscriptionURL(
            props.dataset?.PrimaryOwner?.slice(4),
            props.dataset?.IdInfo.DatabaseName,
            props.dataset?.IdInfo.TableName,
          ),
          '_blank',
        );
      } else {
        window.open(
          getGalaxiSubscriptionURL(
            props.dataset?.PrimaryOwner?.slice(4),
            props.dataset?.IdInfo.DatabaseName,
            props.dataset?.IdInfo.TableName,
          ),
          '_blank',
        );
      }
      return;
    }

    if (isGalaxiPermission && props.dataset?.IdInfo.DataSourceId != GALAXI_DATASOURCE_ID) {
      let activeGroupInfo: GetGroupResult;
      if (props.activeGroup) {
        activeGroupInfo = await getGroupInfo({
          groupId: props.activeGroup,
        });
      }
      const ticketUrl: URL = constructTicketUrlWithParams({
        category: GALAXI_NEW_DATA_ACCESS_CATEGORY,
        type: GALAXI_NEW_DATA_ACCESS_TYPE,
        item: GALAXI_NEW_DATA_ACCESS_ITEM,
        datasetName: props.dataset?.IdInfo.TableName ?? '',
        databaseName: props.dataset.IdInfo.DatabaseName ?? '',
        requesterTeamName: selectedPermission?.teamName ?? activeGroupInfo?.teamInfo?.Name ?? '',
        requesterGAMGroupName: selectedPermission?.value ?? '',
        requesterEmail: activeGroupInfo?.teamInfo?.DistributionList ?? '',
      });
      window.open(ticketUrl);

      return;
    }

    if (
      props.dataset.ClusterIdentifier == null &&
      ((selectedPermission == undefined && selectedPermissions == undefined) ||
        (selectedPrincipalType.value === 'iamRoleUserAccess' && !validateIAMPrincipal()))
    ) {
      return;
    }

    if (selectedPermissions != undefined && selectedPermission == undefined) {
      let cartItems = [];
      for (let permission of selectedPermissions) {
        if (permission.type == FGA_POLICY_TYPE_PREFIX) {
          let cartItem = await flattenFineGrainAccess(permission);
          cartItems.push(cartItem);
        }
      }
      props.addToCart(cartItems);
      props.dismiss();
      return;
    }

    if (selectedPermission.type == RESOURCE_ITEM) {
      let ds = await flattenDatasetAccess(selectedPermission);
      props.addToCart([ds]);
    } else if (selectedPermission.type == TAG_ITEM) {
      let cartItem = await flattenTagAccess(selectedPermission);
      props.addToCart([cartItem]);
    } else if (selectedPermission.type == FGA_POLICY_TYPE_PREFIX) {
      let cartItem = await flattenFineGrainAccess(selectedPermission);
      props.addToCart([cartItem]);
    }
    props.dismiss();
  };

  const transferResources = async (selectedPermissions: []) => {
    setLoadingResources(true);
    let res = [];

    if (selectedPermissions == undefined) {
      return res;
    }

    for (let selectedPermission of selectedPermissions) {
      if (selectedPermission['type'] == FGA_POLICY_TYPE_PREFIX) {
        res.push({
          type: FGA_POLICY_TYPE_PREFIX,
          resource: `${selectedPermission['fgapName']}`,
          resourceLink: createFgaPolicyViewLink(selectedPermission['id']),
        });
      }
    }
    setItems(res);
    setLoadingResources(false);
  };

  const transferResource = async (selectedPermission) => {
    setLoadingResources(true);
    let res = [];

    if (selectedPermission == undefined) {
      return res;
    }

    //named resource
    if (selectedPermission?.type == RESOURCE_ITEM) {
      res.push({
        type: DATASET_RESOURCE_TYPE,
        resource: `${selectedPermission.catalogName}/${selectedPermission.databaseName}/${selectedPermission.datasetName}`,
        resourceLink: createDatasetDetailLink(props.dataset?.Id),
      });
      setItems(res);
    } else if (selectedPermission.type == TAG_ITEM) {
      //tag resource
      let getTaggedResourcesResponse = await getTaggedResources({
        tagPair: selectedPermission.tagKey + ':' + selectedPermission.tagValue,
        catalogId: selectedPermission.catalogId,
        region: props.dataset?.IdInfo?.Region,
        type: props.dataset.ClusterIdentifier != null ? REDSHIFT_TAG_TYPE : LAKEFORMATION_TAG_TYPE,
      });
      for (let resource of getTaggedResourcesResponse.resources) {
        if (resource.clusterId != null) {
          if (resource.resourceType == DATASET_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.schemaName}.${resource.resourceName}`,
              resourceLink: createDatasetDetailLink(resource.resourceId),
            });
          } else if (resource.resourceType == SCHEMA_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}.${resource.clusterId}.${resource.databaseName}.${resource.resourceName}`,
              resourceLink: createSchemaDetailLink(
                resource.catalogId,
                resource.clusterId,
                resource.databaseName,
                resource.resourceName,
                resource.region,
              ),
            });
          }
        } else {
          if (resource.resourceType == DATASET_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}/${resource.databaseName}/${resource.resourceName}`,
              resourceLink: createDatasetDetailLink(resource.resourceId),
            });
          } else if (resource.resourceType == DATABASE_RESOURCE_TYPE) {
            res.push({
              type: resource.resourceType,
              resource: `${selectedPermission.catalogName}/${resource.databaseName}`,
              resourceLink: createGlueDatabaseDetailLink(resource.catalogId, resource.databaseName, resource.region),
            });
          }
        }
      }
      setItems(res);
    }
    setLoadingResources(false);
  };

  const { items } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>no resources attached</b>
          </div>
          <p className='awsui-util-mb-s'>No resources to display.</p>
        </div>
      ),
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const validateIAMPrincipal = () => {
    let errorText = !isValidPrincipalNotRoot(principal)
      ? 'Invalid IAM principal ARN'
      : selectedPermission.principals.filter((dataLakePrincipal) => dataLakePrincipal === principal).length > 0
      ? 'Principal already consumed or is in your cart'
      : undefined;
    setPrincipalErrorText(errorText);
    return errorText === undefined;
  };

  const displayResource = () => {
    return (
      <Table
        loading={loadingResources}
        columnDefinitions={[
          {
            id: 'resource',
            header: 'Resource',
            cell: (item) => <Link to={item.resourceLink}>{item.resource}</Link>,
          },
          {
            id: 'type',
            header: 'Resource type',
            cell: (item) => item.type,
          },
        ]}
        items={items}
        loadingText='Loading resources'
        sortingDisabled
        header={<Header> Resources </Header>}
      />
    );
  };

  const handleChange = (e) => {
    if (selectedPermissionType.value == FGA_POLICY_TYPE_PREFIX) {
      setSelectedPermissions(e.detail.selectedOptions);
      setSelectedPermission(undefined);
      setSelectedPrincipalType(undefined);
      setDropDownPrincipalOptions([
        {
          label: 'Workspace access (Entire account)',
          value: 'workspaceAccess',
          disabled: e.detail.selectedOptions.filter((option) => option.workspaceAccess).length > 0,
        },
        {
          label: 'IAM role/user access',
          value: 'iamRoleUserAccess',
        },
      ]);
      transferResources(e.detail.selectedOptions);
    } else if (isGalaxiPermission) {
      setSelectedPermissions(e.detail.selectedOptions);
      setSelectedPermission(e.detail.selectedOption);
      setSelectedPrincipalType(undefined);
    } else {
      setSelectedPermission(e.detail.selectedOption);
      setSelectedPermissions(undefined);
      setSelectedPrincipalType(undefined);
      setDropDownPrincipalOptions([
        {
          label: 'Workspace access (Entire account)',
          value: 'workspaceAccess',
          disabled: e.detail.selectedOption.workspaceAccess,
        },
        {
          label: 'IAM role/user access',
          value: 'iamRoleUserAccess',
        },
      ]);
      transferResource(e.detail.selectedOption);
    }
  };

  const getDropDownItemsBasedOnSelectionType = () => {
    if (selectedPermissionType) {
      switch (selectedPermissionType.value) {
        case TAG_ITEM:
          return dropdownOptions.filter((dropdownOption) => {
            return dropdownOption.type == TAG_ITEM;
          });
        case FGA_POLICY_TYPE_PREFIX:
          return dropdownOptions.filter((dropdownOption) => {
            return dropdownOption.type == FGA_POLICY_TYPE_PREFIX;
          });
        case GALAXI_TYPE:
          return dropdownOptions.filter((dropdownOption) => {
            return dropdownOption.type == GALAXI_TYPE;
          });
        default:
          return dropdownOptions.filter((dropdownOption) => {
            return dropdownOption.type == RESOURCE_ITEM;
          });
      }
    }
  };
  useEffect(() => {
    getDropDownItemsBasedOnSelectionType();
    setSelectedPermission(undefined);
    setSelectedPermissions(undefined);
    setItems([]);
  }, [selectedPermissionType]);

  return (
    <>
      <Modal
        onDismiss={props.dismiss}
        visible={props.visible}
        closeAriaLabel='Close modal'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={props.dismiss}>
                Cancel
              </Button>
              <Button variant='primary' onClick={handleConfirm}>
                {buttonText}
              </Button>
            </SpaceBetween>
          </Box>
        }
        size='medium'
        header={isGalaxiPermission ? 'Request data access' : 'Select data access approach'}
      >
        <ColumnLayout>
          {!props.preSelectedPermissionType && (
            <FormField label='Select Dataset access permission type'>
              <Select
                selectedOption={selectedPermissionType}
                onChange={({ detail }) => {
                  if (GALAXI_TYPE == detail.selectedOption?.value) {
                    setButtonText('Cut access ticket to Galaxi support team');
                  } else {
                    setButtonText('Add to cart');
                  }
                  setSelectedPermissionType(detail.selectedOption);
                }}
                options={
                  isOnboardedToGalaxi || !isGroupServiceEnabled
                    ? addToCartAccessTypeSelectionWithGalaxi
                    : addToCartAccessTypeSelection
                }
                selectedAriaLabel='Selected'
                empty='No access options'
              />
            </FormField>
          )}
          {selectedPermissionType && !isGalaxiPermission && (
            <FormField label='Select permission(s)'>
              {selectedPermissionType.value != FGA_POLICY_TYPE_PREFIX && (
                <Select
                  selectedOption={selectedPermission}
                  onChange={handleChange}
                  options={getDropDownItemsBasedOnSelectionType()}
                  selectedAriaLabel='Selected'
                  empty='No access options'
                  statusType={loadingResources ? 'loading' : 'finished'}
                  loadingText='Loading access options'
                />
              )}
              {selectedPermissionType.value == FGA_POLICY_TYPE_PREFIX && (
                <Multiselect
                  options={getDropDownItemsBasedOnSelectionType()}
                  selectedAriaLabel='Selected'
                  selectedOptions={selectedPermissions}
                  onChange={handleChange}
                  empty='No access options'
                  statusType={loadingResources ? 'loading' : 'finished'}
                  loadingText='Loading access options'
                />
              )}
            </FormField>
          )}
          {selectedPermissionType && isGalaxiPermission && (
            <>
              {isGroupServiceEnabled && (
                <FormField
                  label='Target galaxi group'
                  description='Select the target galaxi group for your subscription request'
                >
                  <SpaceBetween size='m'>
                    <Select
                      selectedOption={selectedPermission}
                      onChange={handleChange}
                      options={getDropDownItemsBasedOnSelectionType()}
                      selectedAriaLabel='Selected'
                      empty={
                        <a
                          href={
                            'https://w.amazon.com/bin/view/AWS/WWRO/BigDataPlatform/GalaxiAccessManager/Onboarding/'
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          No galaxi groups found. Click here onboard Galaxi.
                        </a>
                      }
                      statusType={loadingResources ? 'loading' : 'finished'}
                      loadingText='Loading access options'
                    />
                    <Alert statusIconAriaLabel='Warning' type='warning'>
                      You will be redirected outside of {WEBSITE_NAME} to continue your subscription.
                    </Alert>
                  </SpaceBetween>
                </FormField>
              )}
              {!isGroupServiceEnabled && (
                <FormField>
                  <Alert statusIconAriaLabel='Warning' type='warning'>
                    You will be redirected outside of {WEBSITE_NAME} to continue your subscription.
                  </Alert>
                </FormField>
              )}
            </>
          )}

          {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
            props.dataset?.ClusterIdentifier == undefined &&
            selectedPermissionType?.value != GALAXI_TYPE && (
              <FormField label='Select Lake Formation access principal type'>
                <Select
                  selectedOption={selectedPrincipalType}
                  onChange={(e) => {
                    setSelectedPrincipalType(e.detail.selectedOption);
                  }}
                  options={dropdownPrincipalOptions}
                  statusType={loadingResources ? 'loading' : 'finished'}
                  loadingText='Loading access options'
                />
              </FormField>
            )}
          {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
            selectedPrincipalType !== undefined &&
            selectedPermissionType?.value != GALAXI_TYPE &&
            selectedPrincipalType.value === 'iamRoleUserAccess' && (
              <FormField
                label='IAM User/Role'
                description='Enter the ARN for the IAM user or role'
                errorText={principalErrorText}
              >
                <Input
                  ariaRequired={true}
                  placeholder={'arn:aws:iam::123456789012:role/AWSDGSFoundry-ManagementRole-DO-NOT-DELETE'}
                  value={principal}
                  onChange={(e) => {
                    setPrincipalErrorText(undefined);
                    setPrincipal(e.detail.value);
                  }}
                />
              </FormField>
            )}
          {(selectedPermission !== undefined || selectedPermissions !== undefined) &&
            !isGalaxiPermission &&
            displayResource()}
        </ColumnLayout>
      </Modal>
    </>
  );
};
