import { matchPath } from 'react-router-dom';
import { Page } from 'src/routes';

export function getActiveHref() {
  const path = window.location.pathname + '/';
  if (path.includes('publisher')) return '/publisher/tables'; // Setting an exception as the publisher route is compound.
  return path.substring(0, path.indexOf('/', 1));
}

export function isHomePage() {
  return getActiveHref() == '/';
}

export function shouldRedirectPage(path): boolean {
  const shouldRedirect =
    // access requests
    matchPath(path, Page.REQUESTS) ||
    matchPath(path, Page.WORKSPACE_MY_REQUESTS) ||
    // data permissions
    matchPath(path, Page.DATASETSHARE_DETAILS) ||
    matchPath(path, Page.LF_PERMISSION_DETAIL) ||
    matchPath(path, Page.PUBLISHED_PERMISSION_DETAIL);

  return !!shouldRedirect;
}

export function isCommonPath(path) {
  return (
    path.startsWith('/search') ||
    path.startsWith('/templates') ||
    path.startsWith('/eventsubscriptions') ||
    path.startsWith('/metadataForms') ||
    path.startsWith('/glossaries') ||
    path.startsWith('/advisories') ||
    path.startsWith('/datasets') ||
    path.startsWith('/databases') ||
    path.startsWith('/catalogs')
  );
}

export function isWorkspacePath(path) {
  const isWksPath =
    isCommonPath(path) ||
    path.startsWith('/tags') ||
    path.startsWith('/notif') ||
    path.startsWith('/advisories') ||
    path.startsWith('/current-workspace/wks') ||
    path.startsWith('/fineGrainAccessPolicies') ||
    path.startsWith('/workspace');

  /** above handles the below paths
     path.startsWith('/workspaces-datasets') ||
     path.startsWith('/workspaces-databases') ||
     path.startsWith('/workspaces-catalogs') ||
     path.startsWith('/workspaces-glue-register') ||
     path.startsWith('/workspaces-redshift-register') ||
     path.startsWith('/workspaces-permission') ||
     path.startsWith('/workspaceaccess') ||
     path.startsWith('/workspaces-requests')
     **/
  return isWksPath;
}

export function isGroupLevelPath(path) {
  const isGrpPath =
    isCommonPath(path) ||
    !isWorkspacePath(path) ||
    path.startsWith('/current-workspace') ||
    path.startsWith('/requests') ||
    path.startsWith('/mydatasets') ||
    path.startsWith('/metric') ||
    path.startsWith('/reporting') ||
    path.startsWith('/groups') ||
    path.startsWith('/publish') ||
    path.startsWith('/resourcegroups') ||
    path.startsWith('/bootstrapactions');
  return isGrpPath;
}
