import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { largePageSizePreference, defaultWrapLinesPreference, i18nStrings, paginationLabels } from 'src/commons/tables';

import { listCatalogs } from '../../../src/api/catalog';
import { Link } from 'react-router-dom';
import { DATA_LOAD_LIMIT, REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { isValidGalaxiGroup, isValidWorkspace } from 'src/commons/validationUtils';
import { createGlueCatalogDetailLink, createGroupDetailLink, createWorkspaceDetailLink } from 'src/routes';
import { EmptyState } from 'src/commons/EmptyState';
import { DefaultRouteProps, getGroupOrWorkspaceName } from 'src/commons/common';
import { getGalaxiGroupURL } from 'src/components/workspaces/common/common';

export interface BrowseCatalogsTableProps extends DefaultRouteProps {
  setContentType: any;
  title: string;
  loading?: boolean;
}

const BrowseCatalogsTable = (props: BrowseCatalogsTableProps) => {
  const [allItems, setItems] = useState([]);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });
  const [totalCount, setTotalCount] = useState(0);

  const [redirect] = useState(undefined);
  const [catalogsLoading, setCatalogsLoading] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [paginationDisabled, setPaginationDisabled] = useState(false);

  const columnDefinitions = [
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) => <Link to={createGlueCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogName}</Link>,
      minWidth: '200px',
      sortingField: 'catalogName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: '200px',
    },
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => item.catalogId,
      minWidth: '100px',
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) =>
        isValidGalaxiGroup(item.owner) ? (
          <a href={getGalaxiGroupURL(getGroupOrWorkspaceName(item.owner, props.workspaceNameMap))}>
            {getGroupOrWorkspaceName(item.owner, props.workspaceNameMap)}
          </a>
        ) : (
          <Link
            to={
              isValidWorkspace(item.owner) ? createWorkspaceDetailLink(item.owner) : createGroupDetailLink(item.owner)
            }
          >
            {getGroupOrWorkspaceName(item.owner, props.workspaceNameMap)}
          </Link>
        ),
      minWidth: '200px',
      sortingField: 'owner',
    },
  ];

  // simplifies the catalog objects in order to make the rest of the code a bit cleaner
  const flatten = (items) => {
    return items.map((item) => ({
      catalogName: item.Name,
      catalogId: item.CatalogId,
      region: item.Region,
      description: item.Description,
      owner: item.Owner,
    }));
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    fetchAllData();
  }, []);

  // Fetch databases and then their catalogs
  const fetchAllData = async () => {
    await fetchCatalogs();
  };

  const fetchCatalogs = async () => {
    setCatalogsLoading(true);
    let request = { Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true };
    const catalogs = await listCatalogs(request);
    setTotalCount(catalogs.TotalCount);
    setNextToken(catalogs.NextToken);
    if (catalogs.NextToken == null) {
      setAllDataLoaded(true);
    }
    let catalogList = catalogs.CatalogInfoList.filter((item) => item.DataSourceId != REDSHIFT_DATASOURCE_ID);
    setItems(catalogList);
    setCatalogsLoading(false);
  };

  const loadMoreData = async () => {
    setPaginationDisabled(true);
    setCatalogsLoading(true);
    let request = {
      NextToken: nextToken,
      Limit: DATA_LOAD_LIMIT,
    };
    let catalogs = await listCatalogs(request);
    setNextToken(catalogs.NextToken);
    let catalogList = catalogs.CatalogInfoList.filter((item) => item.DataSourceId != REDSHIFT_DATASOURCE_ID);
    let currentItems = allItems;
    currentItems.push(...catalogList);
    setItems(currentItems);
    if (catalogs.NextToken == null) {
      setAllDataLoaded(true);
    }
    setCatalogsLoading(false);
    setPaginationDisabled(false);
    return catalogList.length;
  };

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      filtering: {
        noMatch: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No matches</b>
            </div>
            <p className='awsui-util-mb-s'>We can’t find a match.</p>
          </div>
        ),
        empty: <EmptyState title='No matches' subtitle='No catalogs were found.' />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogName',
            groupValuesLabel: 'Catalog names',
          },
          {
            propertyLabel: 'Owner',
            key: 'owner',
            groupValuesLabel: 'Owners',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading catalogs...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            {props.title}
            <span className='awsui-util-header-counter'>{` (${totalCount})`}</span>
          </h2>
        }
        loading={props.loading || catalogsLoading}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover catalogs, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            openEnd={!allDataLoaded}
            disabled={paginationDisabled}
          />
        }
      />
    </>
  );
};

export default BrowseCatalogsTable;
