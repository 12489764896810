import * as React from 'react';
import {
  ColumnLayout,
  ExpandableSection,
  Icon,
  Spinner,
  Button,
  Container,
  Header,
  SpaceBetween,
  Box,
  Modal,
} from '@amzn/awsui-components-react-v3';

import {
  deleteResourceGroup,
  getResourceGroup,
  getBootstrapAction,
  updateResourceGroup,
} from 'src/api/resourcesmanager';
import {
  ErrorAlert,
  LabeledText,
  labeledTextFromList,
  RMPageHeader,
  coloredStatus,
  AuditLogsCard,
  s3url,
} from '../components';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as utils from './resourceGroupUtils';
import { Page } from 'src/routes';
import { ResourcesTable } from './resourcesTable';
import { dateString, pop, set } from '../helpers';
import { DEFAULT_CONTENT_TYPE } from 'src/commons/constants';

export interface resourceGroupDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const ResourceGroupDetails = (props: resourceGroupDetailProps) => {
  const [error, setError] = useState(undefined);
  const [loadingRGs, setLoadingRGs] = useState(true);
  const [resourceGroup, setResourceGroup] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [redirectParams, setRedirectParams] = useState(undefined);
  const [guardrail, setGuardrail] = useState(null);
  const [bootstrapActions, setBootstrapActions] = useState({});

  useEffect(() => {
    props.setContentType(DEFAULT_CONTENT_TYPE);
    handleRefresh();
  }, [props.activeGroup]);

  const getRG = async () => {
    const resourcegroup = await getResourceGroup({
      id: props.match.params.id,
    });

    var bootstrapActions = Object.fromEntries(resourcegroup.emrGroupConfig.bootstrapActions.map((id) => [id, null]));

    setResourceGroup(resourcegroup);
    setLoadingRGs(false);
    setBootstrapActions(bootstrapActions);

    Object.keys(bootstrapActions).map(async (id) => {
      const bsa = await getBootstrapAction({ id });
      var bsas = new Object({ ...bootstrapActions });
      setBootstrapActions(set(bsas, [id], bsa));
    });
  };

  /**
   * Asynchronously fetch and store resource groups.
   */
  const handleRefresh = async () => {
    setLoadingRGs(true);
    try {
      getRG();
    } catch (err) {
      setError({ error: { ...err, while: 'LOADING RESOURCE GROUP' } });
    }
  };

  /**
   * [UNGUARDED]: Calls the deleteResourceGroup API on the current resource group ID and redirects upon success.
   */
  const deleteRG_UNGUARDED = async () => {
    try {
      const response = await deleteResourceGroup({
        id: props.match.params.id,
      });

      if (response.successful) {
        setRedirect(Page.RESOURCEGROUPS);
      } else {
        setError({
          error: {
            ...response,
            code: 'ResponseNotSuccessful',
            while: 'DELETING RESOURCE GROUP',
          },
        });
      }
    } catch (err) {
      setError({ ...err, while: 'DELETING RESOURCE GROUP' });
    }
  };

  /**
   * A guardrailed wrapper around the Delete Resource Groups functions.
   */
  const deleteRG = async () => {
    setGuardrail({
      header: `DELETE ${props.match.params.id}?`,
      action: deleteRG_UNGUARDED,
    });
  };

  /**
   * Redirects user to the Update Resource Group page for the selected resource group ID.
   */
  const updateRG = () => {
    setRedirect(Page.UPDATE_RESOURCEGROUP.replace(':id', props.match.params.id));
  };

  /**
   * Redirects user to the Create Resource Group page, and auto-populates the fields with the current resource group's configuration.
   */
  const cloneRG = () => {
    setRedirect(Page.CREATE_RESOURCEGROUP);
    setRedirectParams({ values: resourceGroup });
  };

  const clearFailureStat = async () => {
    try {
      await updateResourceGroup({
        id: resourceGroup.id,
        status: resourceGroup.status,
      });
      handleRefresh();
    } catch (err) {
      setError({ ...err, while: 'CLEAR FAILURE STAT FOR RESOURCE GROUP' });
    }
  };

  const copiable = true;
  const bsaEntries = Object.entries(bootstrapActions);

  const Guardrail_UNBOUND = () => {
    if (guardrail) {
      var props = new Object({ ...guardrail });
      props['header'] = props['header'] || 'PLEASE CONFIRM ACTION:';
      props['closeAriaLabel'] = props['closeAriaLabel'] || 'Close modal';
      props['visible'] = typeof props['visible'] !== 'undefined' ? props['visible'] : true;
      props['onDismiss'] = () => {
        setGuardrail(null);
      };
      const content = pop(
        props,
        'content',
        [
          'Are you sure you want to continue with the above action?',
          'If so, please click "OK" to proceed.',
          'Otherwise close this box by clicking "Cancel" below or "X" above.',
        ].map((t) => <p>{t}</p>),
      );
      const action = pop(props, 'action', (e) => console.error('No Action Defined. Event: ', e));

      props['footer'] = (
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setGuardrail(null)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={action}>
              OK
            </Button>
          </SpaceBetween>
        </Box>
      );

      return (
        <Modal visible={props['visible']} {...props}>
          {content}
        </Modal>
      );
    } else {
      return [];
    }
  };

  return (
    <>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: redirect,
            state: redirectParams,
          }}
        />
      )}
      {ErrorAlert(error)}
      {Guardrail_UNBOUND()}

      <SpaceBetween size='l'>
        <RMPageHeader
          buttons={[
            {
              text: '',
              icon: 'refresh',
              onItemClick: handleRefresh,
            },
            {
              text: 'Delete',
              onItemClick: deleteRG,
              disabled: !resourceGroup?.status || resourceGroup?.status === 'DELETED',
            },
            {
              text: 'Edit',
              onItemClick: updateRG,
              disabled: !resourceGroup?.status || resourceGroup?.status === 'DELETED',
            },
            {
              text: 'Clone',
              onItemClick: cloneRG,
            },
          ]}
          subheader={resourceGroup?.name}
        />

        <Container header={<Header variant={'h2'}>Resource group details</Header>}>
          <SpaceBetween size='xxl'>
            <ColumnLayout columns={2}>
              {labeledTextFromList(
                [
                  {
                    label: 'Resource group ID',
                    value: resourceGroup?.id,
                    copiable,
                  },
                  {
                    label: 'Name',
                    value: resourceGroup?.name,
                  },
                  {
                    label: 'Owner (Group ID)',
                    value: resourceGroup?.groupId,
                    copiable,
                  },
                  {
                    label: 'Description',
                    value: resourceGroup?.description,
                  },
                  {
                    label: 'Status',
                    value: coloredStatus(resourceGroup?.status),
                  },
                  {
                    label: 'Status reason',
                    value: resourceGroup?.statusReason,
                  },
                ],
                loadingRGs,
              )}
            </ColumnLayout>

            {resourceGroup?.failureStats && resourceGroup?.failureStats.consecutiveFailureCount > 0 ? (
              <ExpandableSection
                header={[
                  <span className='awsui-util-status-negative'>
                    <Icon name='status-negative'></Icon>
                  </span>,
                  ` Failures (${resourceGroup?.failureStats.consecutiveFailureCount})`,
                ]}
                expanded={true}
              >
                <ColumnLayout columns={2}>
                  {labeledTextFromList(
                    [
                      {
                        label: `EC2 subnet ID's tried (` + resourceGroup?.failureStats.ec2SubnetIdsTried.length + ')',
                        value: (
                          <ul>
                            {resourceGroup?.failureStats.ec2SubnetIdsTried.map((id) => (
                              <li>
                                <LabeledText value={id} copiable={true} />
                              </li>
                            ))}
                          </ul>
                        ),
                      },
                      {
                        label: 'Failed resource ID',
                        value: resourceGroup?.failureStats.lastFailedClusterId,
                        copiable,
                      },
                      {
                        label: 'Reason',
                        value: resourceGroup?.failureStats.lastFailureReason,
                      },
                      {
                        label: 'Time',
                        value: dateString(resourceGroup?.failureStats.lastFailureTime),
                      },
                    ],
                    loadingRGs,
                  )}
                  <Button onClick={clearFailureStat}>Reset Failures</Button>
                </ColumnLayout>
              </ExpandableSection>
            ) : (
              <LabeledText label='No failures detected' loading={loadingRGs} />
            )}

            <ExpandableSection header='More details'>
              <ColumnLayout columns={2}>
                {labeledTextFromList(
                  [
                    {
                      label: 'Created by',
                      value: resourceGroup?.createdBy,
                      copiable,
                    },
                    {
                      label: 'Create date',
                      value: dateString(resourceGroup?.createDate),
                    },
                    {
                      label: 'Updated by',
                      value: resourceGroup?.updatedBy,
                      copiable,
                    },
                    {
                      label: 'Update date',
                      value: dateString(resourceGroup?.updateDate),
                    },
                  ],
                  loadingRGs,
                )}
              </ColumnLayout>
            </ExpandableSection>

            <ExpandableSection header='Notification configuration'>
              <pre>
                <LabeledText
                  value={JSON.stringify(resourceGroup?.notification, null, 2)}
                  loading={loadingRGs}
                  copiable
                />
              </pre>
            </ExpandableSection>
          </SpaceBetween>
        </Container>

        <ResourcesTable
          activeGroup={props.activeGroup}
          resourceGroupId={props.match.params.id}
          redirect={(rd, rdp = null) => {
            setRedirect(rd);
            setRedirectParams(rdp);
          }}
        />

        {resourceGroup?.type == 'EMR_GROUP' && (
          <ExpandableSection
            header={<Header variant='h2'> EMR group configuration </Header>}
            variant='container'
            defaultExpanded
          >
            <ColumnLayout columns={3} borders='horizontal'>
              {labeledTextFromList(
                [
                  {
                    label: 'Account ID',
                    value: resourceGroup?.emrGroupConfig.accountId,
                    copiable,
                  },
                  {
                    label: 'Region',
                    value: resourceGroup?.emrGroupConfig.region,
                  },
                  {
                    label: `Subnet ID's`,
                    value: resourceGroup?.emrGroupConfig.ec2SubnetId || '' + resourceGroup?.emrGroupConfig.ec2SubnetIds,
                    copiable,
                  },
                  {
                    label: 'Min clusters',
                    value: resourceGroup?.emrGroupConfig.minClusters || '0',
                  },
                  {
                    label: 'Max clusters',
                    value: resourceGroup?.emrGroupConfig.maxClusters,
                  },
                  {
                    label: 'Auto scaling',
                    value: resourceGroup?.emrGroupConfig.isAutoScalingEnabled ? 'On' : 'Off',
                  },
                  {
                    label: 'Core inst. type',
                    value: resourceGroup?.emrGroupConfig.coreInstanceType,
                    copiable,
                  },
                  {
                    label: 'Core inst. count',
                    value: resourceGroup?.emrGroupConfig.coreInstanceCount,
                  },
                  {
                    label: 'Core inst. market',
                    value: resourceGroup?.emrGroupConfig.coreMarket,
                  },
                  {
                    label: 'Master inst. type',
                    value: resourceGroup?.emrGroupConfig.masterInstanceType,
                    copiable,
                  },
                  {
                    label: 'Master inst. count',
                    value: resourceGroup?.emrGroupConfig.masterInstanceCount,
                  },
                  {
                    label: 'Master inst. market',
                    value: resourceGroup?.emrGroupConfig.masterMarket,
                  },
                  {
                    label: 'Task market',
                    value: resourceGroup?.emrGroupConfig.taskMarket,
                  },
                  {
                    label: 'Task inst. type',
                    value: resourceGroup?.emrGroupConfig.taskInstanceType,
                  },
                  {
                    label: 'Task inst. count',
                    value: resourceGroup?.emrGroupConfig.taskInstanceCount,
                  },
                  {
                    label: 'EMR managed scaling',
                    value: resourceGroup?.emrGroupConfig.isEMRManagedScalingEnabled ? 'On' : 'Off',
                  },
                  {
                    label: 'Managed scaling unit type',
                    value: resourceGroup?.emrGroupConfig.emrManagedScalingConfig?.unitType,
                  },
                  {
                    label: 'Min capacity unit count',
                    value: resourceGroup?.emrGroupConfig.emrManagedScalingConfig?.minimumCapacityUnits || '0',
                  },
                  {
                    label: 'Max capacity unit count',
                    value: resourceGroup?.emrGroupConfig.emrManagedScalingConfig?.maximumCapacityUnits,
                  },
                  {
                    label: 'Max core capacity unit count',
                    value: resourceGroup?.emrGroupConfig.emrManagedScalingConfig?.maximumCoreCapacityUnits,
                  },
                  {
                    label: 'Max on demand capacity unit count',
                    value: resourceGroup?.emrGroupConfig.emrManagedScalingConfig?.maximumOnDemandCapacityUnits,
                  },
                  {
                    label: 'Management role ARN',
                    value: resourceGroup?.emrGroupConfig.emrManagementRoleArn,
                    copiable,
                  },
                  {
                    label: 'Job flow role',
                    value: resourceGroup?.emrGroupConfig.jobFlowRole,
                    copiable,
                  },
                  {
                    label: 'Service role',
                    value: resourceGroup?.emrGroupConfig.serviceRole,
                    copiable,
                  },
                  {
                    label: 'S3 log URI',
                    value: resourceGroup?.emrGroupConfig.s3LogUri,
                    url: s3url(resourceGroup?.emrGroupConfig.s3LogUri),
                    target: '_blank',
                    copiable,
                  },
                  {
                    label: 'Security configuration',
                    value: resourceGroup?.emrGroupConfig.securityConfiguration,
                    copiable,
                  },
                  {
                    label: 'EMR managed master security group',
                    value: resourceGroup?.emrGroupConfig.emrManagedMasterSecurityGroup,
                    copiable,
                  },
                  {
                    label: 'EMR managed slave security group',
                    value: resourceGroup?.emrGroupConfig.emrManagedSlaveSecurityGroup,
                    copiable,
                  },
                  {
                    label: 'Step Concurrency Level',
                    value: resourceGroup?.emrGroupConfig.stepConcurrencyLevel,
                    copiable,
                  },
                  {
                    label: 'Release label',
                    value: resourceGroup?.emrGroupConfig.releaseLabel,
                    copiable,
                  },
                  {
                    label: 'Refresh every',
                    value: `${
                      resourceGroup?.emrGroupConfig.clusterRefreshDays || '30'
                    } days (approx. ${utils.nextRefresh(resourceGroup)} remaining)`,
                  },
                  {
                    label: 'Refresh days',
                    value: utils.chunkifyLists(
                      'clusterRefreshWeekDaysInUTC',
                      resourceGroup?.emrGroupConfig.clusterRefreshWeekDaysInUTC,
                      ['emrGroupConfig'],
                    ),
                  },
                  {
                    label: 'Refresh hours',
                    value: utils.chunkifyLists(
                      'clusterRefreshDayHoursInUTC',
                      resourceGroup?.emrGroupConfig.clusterRefreshDayHoursInUTC,
                      ['emrGroupConfig'],
                    ),
                  },
                  {
                    label: 'Release label',
                    value: resourceGroup?.emrGroupConfig.releaseLabel,
                    copiable,
                  },
                  {
                    label: 'Custom AMI ID',
                    value: resourceGroup?.emrGroupConfig.customAmiId,
                    copiable,
                  },
                  {
                    label: 'Chronicle bootstrap',
                    value: resourceGroup?.emrGroupConfig.installChronicle ? (
                      <span className='awsui-util-status-positive'>
                        <Icon name='status-positive' /> On
                      </span>
                    ) : (
                      <span className='awsui-util-status-inactive'>
                        <Icon name='status-stopped' /> Off
                      </span>
                    ),
                  },
                  {
                    label: 'SSM patch',
                    value: resourceGroup?.emrGroupConfig.installSSMPatch ? (
                      <span className='awsui-util-status-positive'>
                        <Icon name='status-positive' /> On
                      </span>
                    ) : (
                      <span className='awsui-util-status-inactive'>
                        <Icon name='status-stopped' /> Off
                      </span>
                    ),
                  },
                ],
                loadingRGs,
              )}
            </ColumnLayout>

            <ColumnLayout>
              {bsaEntries.length > 0 ? (
                <LabeledText
                  label={`Bootstrap actions (${bsaEntries.length})`}
                  value={
                    <ul>
                      {bsaEntries.map(([id, bsa]) => (
                        <li>
                          <ColumnLayout columns={bsa ? 2 : 1}>
                            <LabeledText
                              label={bsa ? bsa['name'] : <Spinner />}
                              value={id}
                              url={Page.BOOTSTRAPACTION_DETAILS.replace(':id', id)}
                              target={'_blank'}
                              copiable
                            />
                            <LabeledText
                              label='S3 file location'
                              value={bsa ? bsa['s3FileLocation'] : <Spinner />}
                              copiable
                            />
                          </ColumnLayout>
                        </li>
                      ))}
                    </ul>
                  }
                />
              ) : (
                <LabeledText label={'Bootstrap actions'} value={'No boostrap actions found'} />
              )}
            </ColumnLayout>
          </ExpandableSection>
        )}
        <AuditLogsCard auditLogs={resourceGroup?.auditLogs} loading={loadingRGs} />
      </SpaceBetween>
    </>
  );
};
